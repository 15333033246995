@font-face {
  font-family: 'RockfordSansRegular';
  src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'RockfordSansMedium';
  src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'RockfordSansBold';
    font-weight: 900;
    src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'RockfordSansExtraBold';
    font-weight: 900;
    src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-ExtraBold.otf) format('opentype');
}

body {
  margin: 0;
  font-family: RockfordSansRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(189, 188, 188, 0.466); 
  border-radius: 3px;
}